import * as React from "react";
import Seo from "../components/seo";
import Section from "../components/section";
import {Accent, Layout} from '../components/layout';

const privacyPage = () => {
    return (
        <>
            <Layout>
                <Seo title={"Privacy Policy"}/>
                <Section padding={'pb-12'}>
                    <h1 className={'text-2xl font-semibold text-gray-800 mb-4 w-fit'}>Privacy Policy {Accent()}</h1>
                </Section>
            </Layout>
        </>
    )
}

export default privacyPage;